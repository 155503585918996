import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getDomainConfigDetails(){
    return Api().get(`/v2/getdomainconfig`)
  },
  addOrganization(payload) {
    return Api().post(`/v2/organization?organization_id=${organization_id()}`, payload);
  },
  editOrganization(payload, id) {
    return Api().put(`/v2/organization/${id}?organization_id=${organization_id()}`, payload);
  },
   resendCredentialOrg(payload) {
     return Api().post(`/v2/organization/resend?organization_id=${organization_id()}`, payload);
   },

  getAllOrganization(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/organization?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/organization?organization_id=${organization_id()}`);
    } else
      return Api().get(`/v2/organization?organization_id=${organization_id()}`);
  },

};
