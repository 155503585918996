import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
Vue.use(VueLocalStorage);

const TOKEN_KEY = "access_token";
const COUNTRY = "country";
const LANGUAGE = "language";
const PROFILE = "profile";
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return Vue.localStorage.get(TOKEN_KEY);
  },

  saveToken(accessToken) {
    Vue.localStorage.set(TOKEN_KEY, accessToken);
  },

  removeToken() {
    Vue.localStorage.remove(TOKEN_KEY);
  }
};
const ProfileService = {
  getProfile() {
    try {
      return JSON.parse(Vue.localStorage.get(PROFILE));
    } catch (e) {
      return Vue.localStorage.get(PROFILE);
    }
  },

  saveProfile(profile) {
    Vue.localStorage.set(PROFILE, JSON.stringify(profile));
  },

  removeProfile() {
    return Vue.localStorage.remove(PROFILE);
  }
};

const LanguageService = {
  getLanguage() {
    return Vue.localStorage.get(LANGUAGE);
  },

  saveLanguage(language) {
    return Vue.localStorage.set(LANGUAGE, language);
  },

  removeLanguage() {
    return Vue.localStorage.remove(LANGUAGE);
  }
};

const CountryService = {
  getCountry() {
    return JSON.parse(Vue.localStorage.get(COUNTRY));
  },

  saveCountry(country) {
    return Vue.localStorage.set(COUNTRY, JSON.stringify(country));
  },

  removeCountry() {
    return Vue.localStorage.remove(COUNTRY);
  }
};
export {
  TokenService,
  LanguageService,
  CountryService,
  ProfileService
};
