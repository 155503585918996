<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import Vue from "vue";
import { LanguageService, TokenService } from "@/services/storage.service.js";
import UserService from "@/services/UserService.js";
import OrganizationService from "@/services/OrganizationService.js";
export default {
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    }
  },
  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (e) {
      console.log(e);
    }
  },
  created: function() {
    let token = TokenService.getToken();
    if (token) {
      this.$store.dispatch("setPermissions");
    }
  },
  beforeMount() {
    // this.getDomainConfigDetails();
  },
  methods: {
    getDomainConfigDetails() {
      OrganizationService.getDomainConfigDetails()
        .then(response => {
          const { data } = response;
          if (data.Status) {
            let configData = data.data;
            Vue.$cookies.set("theme", configData);
          } else {
            console.log("No Domain Data Found");
          }
        })
        .catch(error => {});
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    handleWindowResize(event) {
      this.$store.dispatch("updateWindowWidth", event.currentTarget.innerWidth);
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.$store.dispatch("updateWindowWidth", window.innerWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>
