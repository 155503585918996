/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import {
  checkPermission,
  setPermissions
} from "../plugins/Permissions";

const mutations = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_USER_ROLE(state, val) {
    state.userRole = val
    localStorage.setItem('userRole', val)
  },
  // UPDATE_STATUS_CHAT(state, value) {
  //     state.AppActiveUser.status = value;
  // },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },

  SET_PERMISSIONS(state, payload) {
    setPermissions();
  },
  SET_CATEGORY_PROJECT_ID(state, payload) {
    state.projectID = payload;
  },

  SET_CATEGORY_NAME(state, payload) {
    state.mainCategoryName = payload;
  },
  SET_BUDGET_DATE(state, payload) {
    state.budgetDate = payload;
  },

  SET_PROJECT_ID(state, payload) {
    state.budgetObject.projectId = payload;
  },
  CLEAR_PROJECT_ID(state, payload) {
    state.budgetObject.projectId = null;
  },

  // CLEAR_BUDGET_LIST(state, payload) {
  //   state.budgetObject.list = [];
  // },

  SET_BUDGET_LIST_PAGE(state, payload){
    state.budgetObject.currentPage = payload;
  },
  SET_IS_DELETE(state, payload){
    state.is_delete = payload.is_delete
  },

  SET_EXPENSE_FILTER(state, payload) {
    state.expenseFilter = payload
  },
  SET_BUDGET_LIST(state, payload) {
    state.budgetObject.list = payload.Records;
    state.budgetObject.pagination.last_page = payload.pagination;
  },

  SET_BUDGET_REPORT_DATA(state, payload) {
    state.budgetReportData = payload;
  },

  SET_TAB_INDEX(state, payload) {
    state.tabIndex = payload;
  },

  SET_BUDGET_VERSION(state, payload) {
    state.budgetversion = payload;
  },

  SET_BUDGET_VERSION_LENGTH(state, payload) {
    state.budgetVersionLength = payload;
  },

  

  SET_SELECTED_CODE(state, payload) {
    state.selectedCode = payload;
  },


  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
    // update the main list
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.index == payload.index)
      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // The Navbar
  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })
    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },
}

export default mutations
