import Api from "./Api.js";

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllBudget(payload) {
    if (payload && payload.hasOwnProperty("page")) {
      return Api().get(
        `/v2/budget/project/${payload.project_id}?page_no=${payload.page
        }&limit=${payload.limit || ""}&organization_id=${organization_id()}&budgetV=${payload.budgetVersion}`
      );
    } else {
      return Api().get(
        `/v2/budget/project/${payload.project_id
        }?organization_id=${organization_id()}&budgetV=${payload.budgetVersion}`
      );
    }
  },

  editBudget(payload, id) {
    return Api().put(
      `/v2/budget/${id}?organization_id=${organization_id()}`,
      payload
    );
  },

  getSingleBudget(id) {
    return Api().get(`/v2/budget/${id}?organization_id=${organization_id()}`);
  },

  getBudgetLogs(id) {
    return Api().get(
      `/v2/budget/logs/${id}?organization_id=${organization_id()}`
    );
  },

  getExpenseBudgetLog(code_name, project_id, budgetVer) {
    return Api().get(
      `v2/budget/getExpensesByBudget/0?organization_id=${organization_id()}&budget_code=${code_name}&project_id=${project_id}&budgetV=${budgetVer}`
    );
  },

  getExpenseLog(id, budgetVersion) {
    https: return Api().get(
      `v2/budget/getExpensesByBudget/${id}?organization_id=${organization_id()}&budgetV=${budgetVersion}`
    );
  },

  getBudgetReport(id, payload) {
    return Api().get(
      `/v2/budget/code/${id}?dateFrom=${payload.from_date}&dateTo=${payload.to_date
      }&organization_id=${organization_id()}`
    );
  },

  getBudgetReportsList(id, budgetVersion) {
    return Api().get(
      `/v2/budget/code/${id}?organization_id=${organization_id()}&budgetV=${budgetVersion}`
    );
  },

  setBudget(payload) {
    return Api().post(
      `/v2/dailybudget?organization_id=${organization_id()}`,
      payload
    );
  },

  getBudgetByDate(dailybudget_date) {
    return Api().get(
      `/v2/dailybudget?dailybudget_date=${dailybudget_date}&organization_id=${organization_id()}`
    );
  },

  getBudgetByProjectId(id, dailybudget_date) {
    return Api().get(
      `/v2/dailybudget?project_id=${id}&dailybudget_date=${dailybudget_date}&organization_id=${organization_id()}`
    );
  },

  addBudget(payload) {
    return Api().post(
      `/v2/budget?organization_id=${organization_id()}`,
      payload
    );
  },

  editDailybudgetBudget(payload, id) {
    return Api().put(
      `/v2/dailybudget/dailybudget_id=${id}?organization_id=${organization_id()}`,
      payload
    );
  },

  getBudgetCodes(id) {
    return Api().get(
      `/v2/budget/getBudgetCodes/${id}?organization_id=${organization_id()}`
    );
  },

  deleteBudget(id, budgetVer) {
    return Api().delete(`v2/budget/${id}?organization_id=${organization_id()}&budgetV=${budgetVer}`);
  },

  moveToBudget(payload) {
    return Api().post(
      `v2/budget/expenses/moveToBudget?organization_id=${organization_id()}`,
      payload
    );
  },

  getATLBudget(project_id) {
    return Api().get(`v2/budget/project/${project_id}/atl`);
  },

  deleteATLBudget(payload = {}) {
    return Api().put(`v2/budget/delete/atl`, payload);
  },

  getCitywiseSummary(project_id) {
    return Api().get(`v2/budget/getCitywiseSummary/${project_id}`);
  },

  getBudgetUploadHistory(project_id) {
    return Api().get(`v2/budget/budgetUploadHistory?organization_id=${organization_id()}&project_id=${project_id}`)
  }
};
